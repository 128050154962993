import { createRef, KeyboardEvent, MouseEvent, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CATEGORIES, Category, ensureCategory } from '../models/category';
import { getSearchParamsInitAsRecord } from '../utils/router';
import Loading from './Loading';

type CategoryTabsProps = {
  children: React.ReactNode;
  loadingPanelContent: boolean;
  panelId?: string;
  disabled?: boolean;
  onTabChange?: (category: Category) => void;
};

function CategoryTabs(props: CategoryTabsProps) {
  const tabPanelId = props.panelId || 'category-tab-panel';

  const [searchParams, setSearchParams] = useSearchParams();
  const [lastFocusedIndex, setLastFocusedIndex] = useState(0);

  const selectedCategory = ensureCategory(searchParams.get('category'));

  const handleClick = (category: Category, event: MouseEvent<HTMLElement>) => {
    if (props.disabled) return;

    const searchParamsInit = getSearchParamsInitAsRecord(searchParams);
    setSearchParams({ ...searchParamsInit, category });
    props.onTabChange?.(category);

    // Stop clicks on the button (or div?) from propagating down (or up?) and
    // thus triggering multiple clicks.
    event.stopPropagation();
  };

  const handleKeydown = (
    index: number,
    event: KeyboardEvent<HTMLButtonElement>
  ) => {
    let newIndex = index;
    switch (event.key) {
      case 'ArrowLeft':
        newIndex = index === 0 ? CATEGORIES.length - 1 : index - 1;
        break;
      case 'ArrowRight':
        newIndex = index === CATEGORIES.length - 1 ? 0 : index + 1;
        break;
      default:
        break;
    }

    tabRefs[newIndex].current?.focus();
    setLastFocusedIndex(newIndex);
  };

  const getTabId = (category: string): string => `category-tab-${category}`;

  const tabRefs = CATEGORIES.map(() => createRef<HTMLButtonElement>());
  const tabs$ = CATEGORIES.map((category, i) => {
    const selected = selectedCategory === category;
    const baseClassName = 'inline-block capitalize px-2 py-1.5 -mb-px';
    const unselectedClassName = `hover-guard:hover:border-b-[6px] hover-guard:hover:border-b-gray-300 ${
      props.disabled ? 'cursor-default pointer-events-none' : 'cursor-pointer'
    }`;
    const selectedClassName =
      'border-b-[6px] border-b-blue-600 text-blue-600 cursor-pointer';
    const className = `${baseClassName} ${
      selected ? selectedClassName : unselectedClassName
    }`;

    return (
      <div
        className={className}
        key={category}
        onClick={(event) => handleClick(category, event)}
      >
        <button
          className={`capitalize px-2 py-0.5 rounded-md font-medium outline-offset ${
            props.disabled && !selected ? 'cursor-default' : 'cursor-pointer'
          }`}
          aria-controls={tabPanelId}
          aria-selected={selected}
          aria-disabled={props.disabled ? true : false}
          role='tab'
          tabIndex={lastFocusedIndex === i ? 0 : -1}
          id={getTabId(category)}
          ref={tabRefs[i]}
          onClick={(event) => handleClick(category, event)}
          onKeyDown={(event) => handleKeydown(i, event)}
        >
          {category}
        </button>
      </div>
    );
  });

  return (
    <div>
      <div
        className='xsm:space-x-2 mb-5 border-b border-b-gray-300'
        role='tablist'
      >
        {tabs$}
      </div>
      <div
        aria-busy={props.loadingPanelContent}
        aria-labelledby={getTabId(selectedCategory)}
        role='tabpanel'
        id={tabPanelId}
      >
        {props.loadingPanelContent ? <Loading /> : props.children}
      </div>
    </div>
  );
}

export default CategoryTabs;
