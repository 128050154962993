type AdProps = {};

function Ad(props: AdProps) {
  return (
    <li className='flex flex-col sm:flex-row sm:items-center py-3 px-5 relative overflow-hidden -mt-px border-pink-400 bg-pink-50 rounded-lg border-2 border-dashed mb-3'>
      <div className='flex items-center'>
        <div className='rounded-lg bg-pink-200 w-10 h-10 mr-6 shrink-0'></div>
        <div>
          <a
            className='font-semibold rounded outline-offset-[3px] hover-guard:hover:text-blue-600'
            href='http://prestigehunt.com'
          >
            Advertise your job opportunity here!
          </a>
          <div className='text-sm text-gray-500'>
            <a
              className='rounded outline-offset-[3px] hover-guard:hover:text-blue-600'
              href='http://prestigehunt.com'
            >
              Company name
            </a>
            <span className='mx-2'>&bull;</span>
            <span>San Francisco</span>
            <span className='mx-2'>&bull;</span>
            <span>$140k - $200k</span>
          </div>
        </div>
      </div>
      <a
        href='mailto:hey@prestigehunt.com'
        className='mt-2 sm:mt-0 ml-16 sm:ml-auto w-fit bg-blue-100 hover-guard:hover:bg-blue-200 text-blue-600 rounded-lg py-2 px-3 font-medium outline-offset-2'
      >
        Contact us
      </a>
    </li>
  );
}

export default Ad;
