import { Tier } from '../models/tier';

type TierBadgeProps = {
  className?: string;
  tier: Tier;
};

function TierBadge(props: TierBadgeProps) {
  const baseClassName = 'inline-block font-bold rounded-lg py-1 px-2.5 text-sm';
  let colorsClassName = '';
  switch (props.tier) {
    case 'S':
      colorsClassName = 'bg-pink-700 text-white';
      break;
    case 'A':
      colorsClassName = 'bg-purple-200 text-purple-800';
      break;
    case 'B':
      colorsClassName = 'bg-violet-200 text-violet-800';
      break;
    case 'C':
      colorsClassName = 'bg-indigo-200 text-indigo-800';
      break;
    case 'D':
      colorsClassName = 'bg-blue-200 text-blue-800';
      break;
    case 'E':
      colorsClassName = 'bg-sky-200 text-sky-800';
      break;
    case 'F':
      colorsClassName = 'bg-cyan-200 text-cyan-800';
      break;
    default:
      colorsClassName = 'bg-transparent text-transparent';
      break;
  }
  const className = `${baseClassName} ${colorsClassName} ${props.className}`;

  return <span className={className}>{props.tier}</span>;
}

export default TierBadge;
