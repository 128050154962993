export type Category = 'tech' | 'finance' | 'consulting';

export const CATEGORIES: ['tech', 'finance', 'consulting'] = [
  'tech',
  'finance',
  'consulting',
];

export const DEFAULT_CATEGORY = CATEGORIES[0];

export function isCategory(candidate: unknown): candidate is Category {
  return (
    candidate === 'tech' ||
    candidate === 'finance' ||
    candidate === 'consulting'
  );
}

export function ensureCategory(candidate: unknown): Category {
  return isCategory(candidate) ? candidate : DEFAULT_CATEGORY;
}
