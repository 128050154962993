import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from 'react';

import { CATEGORIES, Category, DEFAULT_CATEGORY } from '../models/category';
import { postCandidate } from '../services/candidates';
import { capitalize } from '../utils/formatting';
import { getToken } from '../utils/recaptcha';
import Recaptcha from './Recaptcha';
import Select from './Select';

function Add() {
  const [category, setCategory] = useState<Category>(DEFAULT_CATEGORY);
  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    document.title = 'prestigehunt - Add a company';
  }, []);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitted(true);

    const isSpam = document.querySelector<HTMLInputElement>('#hp')?.value;
    if (!isSpam) {
      const token = await getToken();
      await postCandidate(
        {
          name,
          website,
          category,
        },
        token
      );
    }

    setTimeout(() => {
      const submitAnotherButton =
        document.querySelector<HTMLButtonElement>('main button');
      submitAnotherButton?.focus();
    });
  };

  const handleCategoryChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setCategory(event.target.value as Category);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleWebsiteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWebsite(event.target.value);
  };

  const handleAddAnotherClick = (event: MouseEvent<HTMLButtonElement>) => {
    setSubmitted(false);
    setCategory(DEFAULT_CATEGORY);
    setName('');
    setWebsite('');

    setTimeout(() => {
      const firstFormControl =
        document.querySelector<HTMLSelectElement>('main select');
      firstFormControl?.focus();
    });
  };

  return (
    <div>
      <h1 className='text-xl font-bold mb-1'>Add a company</h1>
      <p className='mb-5'>
        Are we missing a prestigious company? If so, submit it here for our team
        to review. We try to review submissions weekly.
      </p>
      {submitted ? (
        <div className='text-center'>
          <button
            className='bg-blue-100 hover-guard:hover:bg-blue-200 text-blue-600 rounded-lg py-2 px-3 font-medium outline-offset-2'
            onClick={handleAddAnotherClick}
          >
            Thanks! Add another?
          </button>
        </div>
      ) : (
        <div className='max-w-md'>
          <form onSubmit={handleSubmit}>
            <div className='mb-5'>
              <Select
                id={'role'}
                options={CATEGORIES.map((c) => ({
                  text: capitalize(c),
                  key: c,
                  value: c,
                }))}
                value={category}
                label={'Role'}
                isRequired={true}
                onChange={handleCategoryChange}
              />
            </div>
            <div className='mb-5'>
              <label className='block mb-2 font-medium' htmlFor='company-name'>
                Company name <span className='text-gray-500'>*</span>
              </label>
              <input
                className='bg-gray-100 py-2 px-3 rounded-lg w-full placeholder:text-gray-500 hover-guard:hover:bg-gray-200'
                id='company-name'
                type='text'
                placeholder='e.g. Google'
                required
                value={name}
                onChange={handleNameChange}
              />
            </div>
            <div className='mb-5'>
              <label className='block mb-2 font-medium' htmlFor='website'>
                Website
              </label>
              <input
                className='bg-gray-100 py-2 px-3 rounded-lg w-full placeholder:text-gray-500 hover-guard:hover:bg-gray-200'
                id='website'
                type='text'
                placeholder='e.g. http://google.com'
                value={website}
                onChange={handleWebsiteChange}
              />
            </div>
            <input
              id='hp'
              name='password'
              className='hidden'
              type='text'
              autoComplete='off'
            />
            <input
              className='bg-blue-600 hover-guard:hover:bg-blue-700 cursor-pointer text-blue-50 rounded-lg py-2 px-3 font-medium w-full outline-offset-2'
              type='submit'
              value='Submit'
            />
          </form>
          <Recaptcha />
        </div>
      )}
    </div>
  );
}

export default Add;
