import { Category } from './category';
import { MIN_HEAD_TO_HEADS } from './headtohead';
import { Tier } from './tier';
import { Trending } from './trending';

export const DEFAULT_RATING = 1000;

export interface Company {
  id: string;
  name: string;
  rating: number;
  rank?: number;
  tier?: Tier;
  headToHeads: number;
  trending: Trending;
  category: Category;
  founded?: number;
  locations?: string[];
  global?: boolean;
  remote?: boolean;
  website?: string;
  levelsWebsite?: string;
  blindWebsite?: string;
  timestamp: number;
  isHFT?: boolean;
  isPreIPO?: boolean;
  hasInternships?: boolean;
}

export interface RankedCompany extends Company {
  rank: number;
}

export function isRanked(company: Company): company is RankedCompany {
  return typeof company.rank === 'number';
}

export function isNewToRankings(company: Company): boolean {
  return company.headToHeads < MIN_HEAD_TO_HEADS;
}
