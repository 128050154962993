import { WithoutIdOrTimestamp } from '../../src/models/utils';
import { HeadToHead } from '../models/headtohead';

export async function postHeadToHead(
  headToHead: WithoutIdOrTimestamp<HeadToHead>,
  token: string
) {
  try {
    const requestInit = {
      method: 'POST',
      body: JSON.stringify({ headToHead, token }),
    };
    await fetch('/.netlify/functions/api/headtoheads', requestInit);
  } finally {
    return Promise.resolve();
  }
}
