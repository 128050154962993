import { Company } from '../models/company';

export async function getCompanies() {
  try {
    const response = await fetch('/.netlify/functions/api/companies');
    if (response.status !== 200) {
      return [];
    }

    return response.json() as Promise<Company[]>;
  } catch {
    return Promise.resolve([]);
  }
}
