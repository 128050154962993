export async function getToken(): Promise<string> {
  return new Promise((resolve, reject) => {
    (window as any).grecaptcha.ready(function () {
      (window as any).grecaptcha
        .execute('6Lek6xgeAAAAAEesy4nsF9w2zSx8uXQaijVPCTIV', {
          action: 'submit',
        })
        .then((token: string) => {
          resolve(token);
        });
    });
  });
}
