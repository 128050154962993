import { useCallback, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { MenuIcon, PlusSmIcon, SwitchVerticalIcon, XIcon } from '@heroicons/react/outline';

import Logo from './Logo';

function Header() {
  const [popupOpen, setPopupOpen] = useState(false);
  const popupId = 'popup';
  const handleDocClick = useCallback(() => {
    if (popupOpen) {
      document.body.classList.remove('no-scroll');
      setPopupOpen(false);
    }
  }, [popupOpen]);
  const baseNavLinkClassName =
    'font-medium rounded-sm outline-offset-4 hover-guard:hover:text-blue-600 flex items-center';
  const getNavLinkClassName = ({ isActive }: { isActive: boolean }) =>
    (isActive ? '' : '') + baseNavLinkClassName;
  const getPopupNavLinkClassName = ({ isActive }: { isActive: boolean }) =>
    'py-3 px-5 block font-medium outline-offset-[-6px] rounded-full flex items-center';

  useEffect(() => {
    document.addEventListener('click', handleDocClick);
    return () => {
      document.removeEventListener('click', handleDocClick);
    };
  }, [handleDocClick]);

  const handleToggleClick = () => {
    document.body.classList.toggle('no-scroll', !popupOpen);
    setPopupOpen(!popupOpen);
  };

  const logoLink$ = (
    <div>
      <Link
        className='flex items-center font-semibold rounded outline-offset-4 hover-guard:hover:text-blue-600 hover-guard:hover:fill-blue-600 focus-visible:outline-none focus-visible:underline focus-visible:underline-offset-4 focus-visible:decoration-blue-900 focus-visible:decoration-4'
        to='/'
      >
        <Logo className='inline mr-1 mt-1 w-7 h-7' />
        <span>prestigehunt</span>
      </Link>
    </div>
  );

  return (
    <div>
      <header className='h-14 z-20 min-w-[320px] relative bg-white'>
        <div className='max-w-screen-md mx-auto h-full px-5 items-center hidden sm:flex'>
          {logoLink$}
          <nav className='ml-20'>
            <ul className='flex space-x-10'>
              <li>
                <NavLink className={getNavLinkClassName} to='/'>
                  Rankings
                </NavLink>
              </li>
              <li>
                <NavLink className={getNavLinkClassName} to='/insights'>
                  Insights
                </NavLink>
              </li>
              <li>
                <NavLink className={getNavLinkClassName} to='/h2h'>
                  <SwitchVerticalIcon className='w-5 h-5 inline mr-1' />
                  H2H
                </NavLink>
              </li>
              <li>
                <NavLink className={getNavLinkClassName} to='/add'>
                  <PlusSmIcon className='w-5 h-5 inline mr-1' />
                  Add
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className='max-w-screen-md mx-auto h-full px-5 items-center justify-center flex sm:hidden'>
          {logoLink$}
          <Link
            className='absolute right-5 p-2 rounded-md bg-blue-100 text-blue-600 hover-guard:hover:bg-blue-200 hover-guard:hover:text-blue-800'
            to='/h2h'
          >
            <SwitchVerticalIcon className='w-5 h-5' />
          </Link>
          <button
            className='absolute left-5 p-2 rounded-md group'
            aria-label='Toggle navigation popup'
            aria-expanded={popupOpen}
            aria-controls={popupId}
            onClick={handleToggleClick}
          >
            {popupOpen ? (
              <XIcon className='w-5 h-5 hover-guard:group-hover:stroke-blue-600' />
            ) : (
              <MenuIcon className='w-5 h-5 hover-guard:group-hover:stroke-blue-600' />
            )}
          </button>
          {popupOpen ? (
            <nav
              className='z-20 bg-white w-full min-w-[320px] mx-auto left-0 top-14 absolute border-t border-t-gray-300 shadow-md'
              id={popupId}
              aria-label='Popup'
            >
              <ul>
                <li className='hover-guard:hover:bg-gray-100'>
                  <NavLink className={getPopupNavLinkClassName} to='/'>
                    Rankings
                  </NavLink>
                </li>
                <li className='hover-guard:hover:bg-gray-100'>
                  <NavLink className={getPopupNavLinkClassName} to='/insights'>
                    Insights
                  </NavLink>
                </li>
                <li className='hover-guard:hover:bg-gray-100'>
                  <NavLink className={getPopupNavLinkClassName} to='/h2h'>
                    H2H
                  </NavLink>
                </li>
                <li className='hover-guard:hover:bg-gray-100'>
                  <NavLink className={getPopupNavLinkClassName} to='/add'>
                    Add
                  </NavLink>
                </li>
                <li className='hover-guard:hover:bg-gray-100'>
                  <NavLink className={getPopupNavLinkClassName} to='/about'>
                    About
                  </NavLink>
                </li>
              </ul>
            </nav>
          ) : null}
        </div>
      </header>
      {popupOpen ? (
        <div className='fixed bg-opacity-50 bg-black top-0 left-0 bottom-0 right-0 z-10'></div>
      ) : undefined}
    </div>
  );
}

export default Header;
