import {
    ChevronDoubleDownIcon, ChevronDoubleUpIcon, ChevronDownIcon, ChevronUpIcon, MinusIcon
} from '@heroicons/react/outline';

import { RankedCompany } from '../models/company';
import { Tier } from '../models/tier';
import { Trending } from '../models/trending';
import Ad from './Ad';
import TierBadge from './TierBadge';

type CompanyListProps = {
  companies: RankedCompany[];
  indexExpanded: number;
  onItemClick: (index: number) => void;
};

function CompanyList(props: CompanyListProps) {
  const getItemHeader$ = (company: RankedCompany) => {
    return (
      <div
        className={`flex text-lg font-medium ${
          company.tier === 'S' ? 'text-white' : 'text-black'
        }`}
      >
        <div className='w-10 shrink-0 ml-4 text-center'>{company.rank}</div>
        <div className='w-5 shrink-0 ml-1 mr-4 flex justify-center pt-[4px]'>
          {getTrendingIcon$(company.trending)}
        </div>
        <div className='text-left pr-14'>{company.name}</div>
      </div>
    );
  };

  const getTrendingIcon$ = (trending: Trending) => {
    switch (trending) {
      case 'super-down':
        return (
          <ChevronDoubleDownIcon
            className={`svg-shadow h-5 w-5 stroke-red-800`}
            aria-hidden={undefined}
            aria-label='Trending very down'
            role='img'
          />
        );
      case 'down':
        return (
          <ChevronDownIcon
            className={`svg-shadow h-5 w-5 stroke-red-600`}
            aria-hidden={undefined}
            aria-label='Trending down'
            role='img'
          />
        );
      case 'super-up':
        return (
          <ChevronDoubleUpIcon
            className={`svg-shadow h-5 w-5 stroke-green-800`}
            aria-hidden={undefined}
            aria-label='Trending very up'
            role='img'
          />
        );
      case 'up':
        return (
          <ChevronUpIcon
            className={`svg-shadow h-5 w-5 stroke-green-600`}
            aria-hidden={undefined}
            aria-label='Trending up'
            role='img'
          />
        );
      case 'none':
        return (
          <MinusIcon
            className={`h-5 w-5 stroke-gray-400`}
            aria-hidden={undefined}
            aria-label='Ranking stable'
            role='img'
          />
        );
    }
  };

  const getMetadataLink$ = (
    linkText: string,
    linkHref: string,
    tier?: Tier
  ) => {
    const metadataLinkClassName = `self-start rounded outline-offset-[3px] underline underline-offset-2 decoration-2 ${
      tier === 'S'
        ? 'text-white/50 hover-guard:hover:text-white/75 focus-visible:outline-blue-200'
        : 'text-black/50 hover-guard:hover:text-black/75'
    }`;
    return (
      <a
        className={metadataLinkClassName}
        href={linkHref}
        target='_blank'
        rel='noreferrer'
      >
        {linkText}
      </a>
    );
  };

  const getTermAndDef$ = (term: string, def: string | number, tier?: Tier) => {
    const termClassName = 'block mb-1 text-sm sm:mb-0 sm:inline-block sm:w-28';
    const defClassName =
      'inline-block font-semibold text-base sm:text-sm sm:text-right sm:w-28';

    return (
      <div>
        <dt className={termClassName}>{term}</dt>
        <dd className={defClassName}>{def}</dd>
      </div>
    );
  };

  const getItemClassName = (
    tier: Tier | undefined,
    firstOfTier: boolean,
    lastOfTier: boolean,
    expanded: boolean
  ) => {
    const baseItemClassName = 'relative overflow-hidden';
    let tierClassName = '';
    switch (tier) {
      case 'S':
        tierClassName = `-mt-px border-black ${
          expanded ? 'bg-slate-900' : 'bg-slate-800'
        }`;
        break;
      case 'A':
        tierClassName = `-mt-px border-purple-500 ${
          expanded ? 'bg-purple-100' : 'bg-purple-50'
        }`;
        break;
      case 'B':
        tierClassName = `-mt-px border-violet-500 ${
          expanded ? 'bg-violet-100' : 'bg-violet-50'
        }`;
        break;
      case 'C':
        tierClassName = `-mt-px border-indigo-500 ${
          expanded ? 'bg-indigo-100' : 'bg-indigo-50'
        }`;
        break;
      case 'D':
        tierClassName = `-mt-px border-blue-500 ${
          expanded ? 'bg-blue-100' : 'bg-blue-50'
        }`;
        break;
      case 'E':
        tierClassName = `-mt-px border-sky-500 ${
          expanded ? 'bg-sky-100' : 'bg-sky-50'
        }`;
        break;
      case 'F':
        tierClassName = `-mt-px border-cyan-500 ${
          expanded ? 'bg-cyan-100' : 'bg-cyan-50'
        }`;
        break;
      default:
        tierClassName = `mt-[2px] rounded-lg ${
          expanded ? 'bg-gray-100' : 'bg-white-50'
        }`;
        break;
    }

    const firstOfTierClassName = firstOfTier ? 'rounded-t-lg' : '';
    const lastOfTierClassName = lastOfTier
      ? 'rounded-b-lg border-b-2 mb-3 bottom-shadow'
      : '';

    return `${baseItemClassName} ${tierClassName} ${firstOfTierClassName} ${lastOfTierClassName}`;
  };

  const getButtonClassName = (tier: Tier | undefined) => {
    const baseItemClassName = 'group w-full py-3 focus-visible:outline-none';
    let tierClassName = '';
    switch (tier) {
      case 'S':
        tierClassName = 'hover-guard:hover:bg-slate-900';
        break;
      case 'A':
        tierClassName = 'hover-guard:hover:bg-purple-100';
        break;
      case 'B':
        tierClassName = 'hover-guard:hover:bg-violet-100';
        break;
      case 'C':
        tierClassName = 'hover-guard:hover:bg-indigo-100';
        break;
      case 'D':
        tierClassName = 'hover-guard:hover:bg-blue-100';
        break;
      case 'E':
        tierClassName = 'hover-guard:hover:bg-sky-100';
        break;
      case 'F':
        tierClassName = 'hover-guard:hover:bg-cyan-100';
        break;
      default:
        tierClassName = 'hover-guard:hover:bg-gray-100';
        break;
    }

    return `${baseItemClassName} ${tierClassName}`;
  };

  const convertTimestamp = (t: number) => {
    const d = new Date(t);
    return d.toLocaleString('default', { month: 'short', year: 'numeric' });
  };

  const handleItemClick = (index: number) => {
    props.onItemClick(props.indexExpanded === index ? -1 : index);
  };

  const companies$ = props.companies.map((company, i) => {
    const firstOfTier =
      !!company.tier &&
      (i === 0 || company.tier !== props.companies[i - 1]?.tier);
    const lastOfTier =
      !!company.tier &&
      (i + 1 === props.companies.length ||
        company.tier !== props.companies[i + 1]?.tier);
    const expanded = i === props.indexExpanded;

    return (
      <li
        className={getItemClassName(
          company.tier,
          firstOfTier,
          lastOfTier,
          expanded
        )}
        key={company.id}
      >
        {firstOfTier ? (
          <TierBadge className='absolute right-2 top-2' tier={company.tier!} />
        ) : undefined}
        <button
          aria-expanded={expanded}
          className={getButtonClassName(company.tier)}
          onClick={() => handleItemClick(i)}
        >
          <span
            className={`absolute left-0 h-[28px] w-[6px] z-10 invisible group-focus-visible:visible ${
              company.tier === 'S' ? 'bg-blue-200' : 'bg-blue-900'
            }`}
          ></span>
          {getItemHeader$(company)}
        </button>
        {expanded ? (
          <span className='absolute top-3 bottom-3 left-0 w-[6px] bg-blue-600'></span>
        ) : null}
        {expanded ? (
          <div className='flex leading-4 justify-center xsm:justify-start'>
            <div
              className={`mt-4 mb-6 ml-0 flex flex-col pr-6 border-r-2 ${
                company.tier === 'S' ? 'border-white/10' : 'border-black/10'
              } xsm:ml-[6rem] xsm:pr-10`}
            >
              <dl
                className={`space-y-4 sm:space-y-2 ${
                  company.tier === 'S' ? 'text-white' : 'text-black'
                }`}
              >
                {getTermAndDef$(
                  'ELO rating',
                  Math.round(company.rating),
                  company.tier
                )}
                {getTermAndDef$('H2Hs', company.headToHeads, company.tier)}
                {getTermAndDef$(
                  'Date added',
                  convertTimestamp(company.timestamp),
                  company.tier
                )}
              </dl>
            </div>
            <div className='text-sm mt-4 mb-6 ml-6 flex flex-col space-y-2 xsm:ml-10'>
              {company.website
                ? getMetadataLink$('Website', company.website, company.tier)
                : null}
              {company.levelsWebsite
                ? getMetadataLink$(
                    'Levels.fyi',
                    company.levelsWebsite,
                    company.tier
                  )
                : null}
              {company.blindWebsite
                ? getMetadataLink$('Blind', company.blindWebsite, company.tier)
                : null}
            </div>
          </div>
        ) : null}
      </li>
    );
  });

  // If we have companies, show an ad.
  // Show the ad immediately after the S tier.
  // If there is no S tier, show it at the top.
  // If there is only S tier, show it at the bottom.
  if (props.companies.length > 0) {
    let indexAfterSTier = props.companies.findIndex((c) => c.tier !== 'S');
    if (indexAfterSTier === -1) {
      indexAfterSTier = props.companies.length;
    }

    companies$.splice(indexAfterSTier, 0, <Ad key='ad' />);
  }

  return <ul>{companies$}</ul>;
}

export default CompanyList;
