import { Candidate } from '../models/candidate';
import { WithoutIdOrTimestamp } from '../models/utils';

export async function postCandidate(
  candidate: WithoutIdOrTimestamp<Candidate>,
  token: string
) {
  try {
    const requestInit = {
      method: 'POST',
      body: JSON.stringify({ candidate, token }),
    };
    await fetch('/.netlify/functions/api/candidates', requestInit);
  } finally {
    return Promise.resolve();
  }
}
