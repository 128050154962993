import { EmojiSadIcon } from '@heroicons/react/outline';

function ErrorMessage() {
  return (
    <div className='text-gray-600 flex items-center justify-center'>
      <EmojiSadIcon className='w-5 h-5 mr-1 hidden xsm:inline' /> Uh oh, the
      backend is on fire. Try coming back a bit later.
    </div>
  );
}

export default ErrorMessage;
