import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CheckIcon, RefreshIcon } from '@heroicons/react/outline';
import { announce, clearAnnouncer } from '@react-aria/live-announcer';

import { ensureCategory } from '../models/category';
import { Company } from '../models/company';
import { HeadToHead } from '../models/headtohead';
import { WithoutIdOrTimestamp } from '../models/utils';
import { postHeadToHead } from '../services/headtoheads';
import { isMobile } from '../utils/mobile';
import { getToken } from '../utils/recaptcha';
import { CompaniesContext } from './App';
import CategoryTabs from './CategoryTabs';
import ErrorMessage from './ErrorMessage';
import InlineLink from './InlineLink';
import Loading from './Loading';
import Recaptcha from './Recaptcha';

const getTwoCompanies = (companies: Company[]): [Company, Company] => {
  const indexA = Math.floor(Math.random() * companies.length);
  let indexB = Math.floor(Math.random() * companies.length);
  while (indexA === indexB) {
    indexB = Math.floor(Math.random() * companies.length);
  }

  const companyA = companies[indexA];
  const companyB = companies[indexB];

  return [companyA, companyB];
};

function Match() {
  const [searchParams] = useSearchParams();
  const { companies, loading, error } = useContext(CompaniesContext);
  const [winner, setWinner] = useState<string | null>(null);
  const [twoCompanies, setTwoCompanies] = useState<[Company, Company] | null>(
    null
  );
  const [slideUpTrigger, setSlideUpTrigger] = useState<number>(0);
  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
  const selectedCategory = ensureCategory(searchParams.get('category'));

  useEffect(() => {
    document.title = 'prestigehunt - Head-to-head';
  }, []);

  useEffect(() => {
    const categoryCompanies = companies.filter(
      (company) => company.category === selectedCategory
    );
    const twoCompanies =
      !loading && categoryCompanies.length > 0
        ? getTwoCompanies(categoryCompanies)
        : null;
    setTwoCompanies(twoCompanies);
  }, [loading, companies, selectedCategory]);

  const handleClick = async (id: string) => {
    if (!twoCompanies) return;
    if (winner) return;

    const [companyA, companyB] = twoCompanies;
    const aWins = companyA.id === id;

    setWinner(aWins ? companyA.id : companyB.id);

    // This timeout improves rendering performance on iOS Chrome.
    setTimeout(async () => {
      const headToHead: WithoutIdOrTimestamp<HeadToHead> = {
        a: companyA.id,
        b: companyB.id,
        winner: aWins ? 'a' : 'b',
        processed: false,
      };
      const token = await getToken();
      await postHeadToHead(headToHead, token);
      await delay(300);
      refreshMatch();
    });
  };

  const refreshMatch = () => {
    setWinner(null);
    const categoryCompanies = companies.filter(
      (company) => company.category === selectedCategory
    );

    setTwoCompanies(getTwoCompanies(categoryCompanies));
    setSlideUpTrigger(slideUpTrigger + 1);
    clearAnnouncer('polite');
    announce('New head to head ready.', 'polite');

    // Because refs aren't working for some reason (always null).
    setTimeout(() => {
      const firstButton = document.getElementById('button-0');
      firstButton?.focus();
    });
  };

  const getButton$ = (index: 0 | 1) => {
    if (!twoCompanies) return;

    const company = twoCompanies[index];
    const className = `flex items-center p-5 rounded-lg bg-gray-200 block w-full text-left font-medium outline-offset-2 hover-guard:hover:bg-blue-200 ${
      winner
        ? company.id === winner
          ? 'bg-green-400 hover-guard:hover:bg-green-400'
          : 'hover-guard:hover:bg-gray-200'
        : ''
    } ${isMobile() ? 'outline-none' : ''}`;
    return (
      <button
        className={className}
        aria-pressed={winner ? company.id === winner : undefined}
        onClick={() => handleClick(company.id)}
        id={`button-${index}`}
      >
        {company.name}
        {company.id === winner ? (
          <div className='ml-auto'>
            <CheckIcon className='w-5 h-5' />
          </div>
        ) : null}
      </button>
    );
  };

  return (
    <div>
      <h1 className='text-xl font-bold mb-1'>Head-to-head</h1>
      <p className='mb-5'>
        Which company has the most prestigious prestige? These head-to-head
        comparisons power our rankings.{' '}
        <InlineLink
          to='/about#rankings'
          text='Learn more'
          ariaLabel='Learn more about how our rankings work.'
        />
      </p>
      <CategoryTabs loadingPanelContent={loading} disabled={!!winner}>
        {loading ? (
          <Loading />
        ) : error || !twoCompanies ? (
          <ErrorMessage />
        ) : (
          <div className='slide-up' key={slideUpTrigger}>
            <div className='space-y-2 '>
              {getButton$(0)}
              {getButton$(1)}
            </div>
            <div className='mt-7 flex justify-center'>
              <button
                className='text-gray-600 hover-guard:hover:text-gray-800 rounded-lg py-2 px-3 font-medium'
                onClick={refreshMatch}
              >
                Skip
                <RefreshIcon className='w-5 h-5 inline ml-2' />
              </button>
            </div>
            <div className='max-w-md text-center mx-auto'>
              <Recaptcha />
            </div>
          </div>
        )}
      </CategoryTabs>
    </div>
  );
}

export default Match;
