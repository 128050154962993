import { URLSearchParams } from 'url';

export function getSearchParamsInitAsRecord(
  searchParams: URLSearchParams
): Record<string, string | string[]> {
  const searchParamsPairs = Array.from(searchParams.entries());
  const searchParamsInit: Record<string, string | string[]> = {};
  for (const pair of searchParamsPairs) {
    searchParamsInit[pair[0]] = pair[1];
  }
  return searchParamsInit;
}
