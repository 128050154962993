import 'chartjs-adapter-date-fns';

import { Chart, registerables } from 'chart.js';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { useSearchParams } from 'react-router-dom';

import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/outline';
import { announce } from '@react-aria/live-announcer';

import { Category, ensureCategory } from '../models/category';
import { isRanked, RankedCompany } from '../models/company';
import { getSnapshots, getSnapshotsByCompanyId } from '../services/snapshots';
import { getSearchParamsInitAsRecord } from '../utils/router';
import { CompaniesContext } from './App';
import CategoryTabs from './CategoryTabs';
import ErrorMessage from './ErrorMessage';
import Loading from './Loading';
import Select from './Select';

const SCREEN_WIDTH_PX_FOR_SMALL_CHART = 500;
const CHART_WIDTH_PX_FOR_SMALL_CHART = SCREEN_WIDTH_PX_FOR_SMALL_CHART - 40;

type RanksDataPoint = {
  x: number;
  y: number | null;
};

type RanksState = {
  ranks: RanksDataPoint[];
  loading: boolean;
  error: boolean;
};

const initialRanksState: RanksState = {
  ranks: [],
  loading: true,
  error: false,
};

type Mover = {
  name: string;
  from: number;
  to: number;
};

type MoversState = {
  up?: Mover;
  down?: Mover;
  loading: boolean;
  error: boolean;
};

const initialMoversState: MoversState = {
  loading: true,
  error: false,
};

type ELODataPoint = {
  x: string;
  y: number;
};

function constructELOData(companies: RankedCompany[]): ELODataPoint[] {
  const binSize = 20;
  const binCount = 50;
  const maxBin = 1500; // Min === 500
  const bins = [...Array(binCount).keys()].map((i) => maxBin - i * binSize);
  const elos = [];
  let i = 0;
  let j = 0;
  let count = 0;
  while (j < companies.length) {
    if (bins[i] === undefined) {
      // Something bad happened... break out to avoid an infinite loopity-loop.
      break;
    }

    const rating = companies[j].rating;
    if (rating >= bins[i] && rating <= bins[i] + binSize) {
      count++;
      j++;
    } else {
      elos.push({
        x: `${bins[i]} - ${bins[i] + binSize}`,
        y: count,
      });
      count = 0;
      i++;
    }
  }

  elos.push({
    x: `${bins[i]} - ${bins[i] + binSize}`,
    y: count,
  });

  // Remove 0s at the front.
  let k = 0;
  for (const elo of elos) {
    if (elo.y === 0) {
      k++;
    } else {
      break;
    }
  }
  elos.splice(0, k);

  // Data is currently from high -> low. Flip it.
  elos.reverse();

  return elos;
}

function Insights() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [ranksState, setRanksState] = useState<RanksState>(initialRanksState);
  const [moversState, setMoversState] =
    useState<MoversState>(initialMoversState);
  const companiesState = useContext(CompaniesContext);

  const selectedCategory = ensureCategory(searchParams.get('category'));

  const rankedCompanies = companiesState.companies
    .filter((company) => company.category === selectedCategory)
    .filter(isRanked)
    .sort((a, b) => a.rank - b.rank);
  const rankedCompaniesAlphabetized = [...rankedCompanies].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const defaultSelectedCompanyId =
    rankedCompanies.length > 0 ? rankedCompanies[0].id : null;
  const selectedCompanyId =
    searchParams.get('companyId') || defaultSelectedCompanyId;

  const elos = constructELOData(rankedCompanies);

  useEffect(() => {
    document.title = 'prestigehunt - Insights';
  }, []);

  useEffect(() => {
    if (companiesState.loading || !selectedCompanyId) return;

    const fetchRanks = async () => {
      try {
        setRanksState((prev) => {
          // Don't clear the current ranks data. That causes the chart to be wiped and
          // the page scroll to be all janky. We'll keep the current (stale) data and
          // dim the chart.
          return {
            ...prev,
            loading: true,
            error: false,
          };
        });

        const snapshots = await getSnapshots(selectedCompanyId);
        const ranks = snapshots.map((snapshot) => ({
          x: snapshot.timestamp,
          y: snapshot.company.rank ?? null,
        }));

        setRanksState({
          ranks,
          loading: false,
          error: false,
        });
      } catch {
        setRanksState({
          ranks: [],
          loading: false,
          error: true,
        });
      } finally {
        announce('Loading finished.', 'polite');
      }
    };

    fetchRanks();
  }, [
    selectedCategory,
    selectedCompanyId,
    companiesState.loading,
    companiesState.companies,
  ]);

  useEffect(() => {
    if (companiesState.loading) return;

    const fetchSnapshotsByCompanyId = async () => {
      try {
        setMoversState({
          up: undefined,
          down: undefined,
          loading: true,
          error: false,
        });

        const companyIdsForCategory = new Set<string>();
        for (const company of companiesState.companies) {
          if (company.category === selectedCategory) {
            companyIdsForCategory.add(company.id);
          }
        }

        const timestamp7DaysAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;
        const snapshotsByCompanyId = await getSnapshotsByCompanyId({
          after: timestamp7DaysAgo,
        });

        let min = Infinity;
        let max = -Infinity;

        let candidateMoverUp: Mover | undefined = undefined;
        let candidateMoverDown: Mover | undefined = undefined;

        const companyIds = [...snapshotsByCompanyId.keys()].filter(
          (companyId) => companyIdsForCategory.has(companyId)
        );
        for (const companyId of companyIds) {
          const snapshots = snapshotsByCompanyId.get(companyId);
          if (!snapshots || snapshots.length === 0) continue;

          const fromRank = snapshots[snapshots.length - 1].company.rank;
          const toRank = snapshots[0].company.rank;
          if (fromRank === undefined || toRank === undefined) continue;

          const rankChange = fromRank - toRank;

          if (min > rankChange) {
            min = rankChange;
            candidateMoverDown = {
              name: snapshots[0].company.name,
              from: fromRank,
              to: toRank,
            };
          }

          if (max < rankChange) {
            max = rankChange;
            candidateMoverUp = {
              name: snapshots[0].company.name,
              from: fromRank,
              to: toRank,
            };
          }
        }

        if (
          candidateMoverUp === undefined ||
          candidateMoverDown === undefined
        ) {
          setMoversState({
            loading: false,
            error: true,
          });
        } else {
          setMoversState({
            up: candidateMoverUp,
            down: candidateMoverDown,
            loading: false,
            error: false,
          });
        }
      } catch {
        setMoversState({
          loading: false,
          error: true,
        });
      } finally {
        announce('Loading finished.', 'polite');
      }
    };

    fetchSnapshotsByCompanyId();
  }, [selectedCategory, companiesState.loading, companiesState.companies]);

  const handleCompanyChartChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const companyId = event.target.value;
    const searchParamsInit = getSearchParamsInitAsRecord(searchParams);
    setSearchParams({ ...searchParamsInit, companyId });
  };

  // Remove the now invalid company id and update the category (stupid how we have to do
  // the latter).
  const handleTabChange = (category: Category) => {
    const { companyId, ...withoutCompanyId } =
      getSearchParamsInitAsRecord(searchParams);
    setSearchParams({ ...withoutCompanyId, category });
  };

  Chart.register(...registerables);
  Chart.defaults.font.family = 'Inter';

  const layoutOptions = {
    padding: {
      top: 5,
    },
  };

  const tooltipOptions = {
    displayColors: false,
    caretPadding: 8,
    titleFont: {
      weight: '500',
      size: 14,
    },
    bodyFont: {
      size: 14,
    },
    mode: 'index',
    intersect: false,
  };

  const getTitleOptions = (text: string) => ({
    display: true,
    text,
    font: {
      weight: 'normal',
      size: 14,
    },
    padding: 20,
  });

  const ranksChartData = {
    datasets: [
      {
        data: ranksState.ranks,
      },
    ],
  };

  const ranksChartOptions = {
    borderCapStyle: 'round',
    stepped: true,
    pointBackgroundColor: 'transparent',
    pointBorderColor: 'transparent',
    backgroundColor: 'rgb(37, 99, 235)',
    borderColor: 'rgb(37, 99, 235)',
    fill: {
      target: 'start',
      above: 'rgb(37, 99, 235, 0.1)',
    },
    layout: layoutOptions,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: tooltipOptions,
      title: getTitleOptions('Ranking over time'),
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month',
        },
        ticks: {
          maxTicksLimit:
            window.innerWidth < SCREEN_WIDTH_PX_FOR_SMALL_CHART ? 3 : 8,
        },
      },
      y: {
        reverse: true,
        max: rankedCompanies.length,
        ticks: {
          stepSize: 1,
          includeBounds: false,
          maxTicksLimit:
            window.innerWidth < SCREEN_WIDTH_PX_FOR_SMALL_CHART ? 3 : 7,
        },
        title: {
          display: true,
          text: 'Ranking',
        },
      },
    },
    onResize: (chart: Chart, size: any) => {
      chart.options.scales!.x!.ticks!.maxTicksLimit =
        size.width < CHART_WIDTH_PX_FOR_SMALL_CHART ? 3 : 8;
      chart.options.scales!.y!.ticks!.maxTicksLimit =
        size.width < CHART_WIDTH_PX_FOR_SMALL_CHART ? 3 : 7;
    },
  };

  const eloChartData = {
    datasets: [
      {
        data: elos,
      },
    ],
  };
  const eloChartOptions = {
    borderWidth: 0,
    backgroundColor: 'rgb(37, 99, 235)',
    borderRadius: 2,
    layout: layoutOptions,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: tooltipOptions,
      title: getTitleOptions('ELO histogram'),
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'ELO bin',
        },
      },
      y: {
        title: {
          display: true,
          text: '# of companies',
        },
      },
    },
  };

  const getScorecard$ = (mover: Mover, up: boolean) => {
    const pillBackgroundColor = up ? 'bg-green-900' : 'bg-red-900';
    const backgroundColor = up ? 'bg-green-100' : 'bg-red-100';

    return (
      <div className={`${backgroundColor} rounded-lg p-4 inline-block`}>
        <div className='flex justify-between xxsm:space-x-10'>
          <div>
            <div className='text-lg mb-1 font-medium'>{mover.name}</div>
            <div>
              <span className={`text-3xl font-semibold`}>{mover.to}</span>
              <span className='text-sm ml-2 font-medium'>
                from {mover.from}
              </span>
            </div>
          </div>
          <div
            className={`self-end rounded-full font-medium py-0.5 px-1 inline-flex ${pillBackgroundColor} text-white`}
          >
            {up ? (
              <ArrowSmUpIcon className='w-6 h-6 inline' />
            ) : (
              <ArrowSmDownIcon className='w-6 h-6 inline' />
            )}
            <span className='pr-[6px]'>{Math.abs(mover.to - mover.from)}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h1 className='text-xl font-bold mb-1'>Insights</h1>
      <p className='mb-5'>
        We collaborate with top statisticians to perform cutting-edge data
        analysis on our rich corpus of prestige data and tease out useful
        insights about our rankings.
      </p>
      <CategoryTabs
        loadingPanelContent={companiesState.loading}
        disabled={ranksState.loading || moversState.loading}
        onTabChange={handleTabChange}
      >
        {companiesState.error ? (
          <ErrorMessage />
        ) : (
          <div>
            <h2 className='text-lg font-semibold mb-1'>Biggest movers</h2>
            <p className='mb-5'>
              Who were the biggest movers up and down the rankings over the{' '}
              <span className='font-semibold'>past 7 days</span>?
            </p>
            <div className='mb-10'>
              {moversState.error ? (
                <ErrorMessage />
              ) : moversState.loading ? (
                <Loading />
              ) : (
                <div className='flex flex-col xxsm:items-start space-y-5 sm:space-y-0 sm:block sm:space-x-5'>
                  {getScorecard$(moversState.up!, true /* up */)}
                  {getScorecard$(moversState.down!, false /* up */)}
                </div>
              )}
            </div>
            <h2 className='text-lg font-semibold mb-1'>Trends</h2>
            <p className='mb-5'>
              Select a company below to understand how its ranking has changed
              over time.
            </p>
            {selectedCompanyId ? (
              <div className='mb-5'>
                <Select
                  id={'company'}
                  options={rankedCompaniesAlphabetized.map((c) => ({
                    text: c.name,
                    key: c.id,
                    value: c.id,
                  }))}
                  value={selectedCompanyId}
                  onChange={handleCompanyChartChange}
                />
              </div>
            ) : null}
            <div className='mb-10'>
              {ranksState.error ? (
                <ErrorMessage />
              ) : ranksState.loading && ranksState.ranks.length === 0 ? (
                <Loading />
              ) : (
                <div className='relative'>
                  {ranksState.loading ? (
                    <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                      <Loading />
                    </div>
                  ) : null}
                  <Line
                    aria-hidden={ranksState.loading}
                    className={
                      ranksState.loading ? 'opacity-20 pointer-events-none' : ''
                    }
                    options={ranksChartOptions as any}
                    data={ranksChartData}
                  />
                </div>
              )}
            </div>
            <h2 className='text-lg font-semibold mb-1'>ELO histogram</h2>
            <p>Histogram of current ELO ratings.</p>
            <div>
              <Bar options={eloChartOptions as any} data={eloChartData} />
            </div>
          </div>
        )}
      </CategoryTabs>
    </div>
  );
}

export default Insights;
