import { ChangeEvent } from 'react';

import { ChevronDownIcon } from '@heroicons/react/outline';

type Option = {
  text: string;
  key: string;
  value: string;
};

type SelectProps = {
  options: Option[];
  id: string;
  label?: string;
  value: string;
  isRequired?: boolean;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
};

function Select(props: SelectProps) {
  return (
    <div>
      {props.label ? (
        <label className='block mb-2 font-medium' htmlFor={props.id}>
          {props.label}{' '}
          {props.isRequired ? <span className='text-gray-500'>*</span> : null}
        </label>
      ) : null}
      <div className='relative max-w-md w-full'>
        <select
          className='bg-gray-100 py-2 px-3 rounded-lg w-full placeholder:text-gray-500 hover-guard:hover:bg-gray-200 appearance-none'
          id={props.id}
          required
          value={props.value}
          onChange={props.onChange}
        >
          {props.options.map((c) => {
            return (
              <option key={c.key} value={c.value}>
                {c.text}
              </option>
            );
          })}
        </select>
        <ChevronDownIcon className='w-5 h-5 absolute right-2.5 top-2.5 pointer-events-none' />
      </div>
    </div>
  );
}

export default Select;
