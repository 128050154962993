import { createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { announce } from '@react-aria/live-announcer';

import { Company } from '../models/company';
import { getCompanies } from '../services/companies';
import About from './About';
import Add from './Add';
import Insights from './Insights';
import Layout from './Layout';
import Match from './Match';
import Rankings from './Rankings';

type CompaniesState = {
  companies: Company[];
  loading: boolean;
  error: boolean;
};

const initialCompaniesState: CompaniesState = {
  companies: [],
  loading: true,
  error: false,
};

export const CompaniesContext = createContext<CompaniesState>(
  initialCompaniesState
);

function App() {
  const [companiesState, setCompaniesState] = useState<CompaniesState>(
    initialCompaniesState
  );

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companies = await getCompanies();
        setCompaniesState({
          companies,
          loading: false,
          error: false,
        });
      } catch {
        setCompaniesState({
          companies: [],
          loading: false,
          error: true,
        });
      } finally {
        announce('Loading finished.', 'polite');
      }
    };

    fetchCompanies();
  }, []);

  return (
    <CompaniesContext.Provider value={companiesState}>
      <Router>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Rankings />} />
            <Route path='insights' element={<Insights />} />
            <Route path='h2h' element={<Match />} />
            <Route path='add' element={<Add />} />
            <Route path='about' element={<About />} />
          </Route>
        </Routes>
      </Router>
    </CompaniesContext.Provider>
  );
}

export default App;
