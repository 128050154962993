import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className='flex text-gray-500 max-w-screen-md min-w-[320px] mx-auto px-5 mt-20 pb-5 flex-col sm:flex-row'>
      <div>&copy; 2022</div>
      <div className='sm:ml-10 sm:mt-0 mt-5 sm:space-x-7 sm:space-y-0 space-y-2.5 flex sm:flex-row flex-col'>
        <Link
          className='text-gray-500 w-fit rounded outline-offset-4 hover-guard:hover:text-blue-600'
          to='/about'
        >
          About
        </Link>
        <a
          className='text-gray-500 w-fit rounded outline-offset-4 hover-guard:hover:text-blue-600'
          href='https://prestigehunt.bearblog.dev/blog'
          target='_blank'
          rel='noreferrer'
        >
          Blog
        </a>
        <a
          className='text-gray-500 w-fit rounded outline-offset-4 hover-guard:hover:text-blue-600'
          href='mailto:hey@prestigehunt.com'
          target='_blank'
          rel='noreferrer'
        >
          Contact
        </a>
        <a
          className='text-gray-500 w-fit rounded outline-offset-4 hover-guard:hover:text-blue-600'
          href='https://buymeacoffee.com/prestigehunt'
          target='_blank'
          rel='noreferrer'
        >
          Buy us a ☕
        </a>
        <a
          className='w-fit rounded outline-offset-4 flex items-center fill-gray-500 hover-guard:hover:fill-blue-600'
          href='https://twitter.com/prestigehunt'
          target='_blank'
          rel='noreferrer'
        >
          <svg
            className='h-5 fill-inherit'
            aria-hidden='true'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z' />
          </svg>
        </a>
        <a
          className='w-fit rounded outline-offset-4 flex items-center fill-gray-500 hover-guard:hover:fill-blue-600'
          href='https://github.com/prestigehunt/prestigehunt'
          target='_blank'
          rel='noreferrer'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            aria-hidden='true'
          >
            <path d='M10.9,2.1c-4.6,0.5-8.3,4.2-8.8,8.7c-0.5,4.7,2.2,8.9,6.3,10.5C8.7,21.4,9,21.2,9,20.8v-1.6c0,0-0.4,0.1-0.9,0.1 c-1.4,0-2-1.2-2.1-1.9c-0.1-0.4-0.3-0.7-0.6-1C5.1,16.3,5,16.3,5,16.2C5,16,5.3,16,5.4,16c0.6,0,1.1,0.7,1.3,1c0.5,0.8,1.1,1,1.4,1 c0.4,0,0.7-0.1,0.9-0.2c0.1-0.7,0.4-1.4,1-1.8c-2.3-0.5-4-1.8-4-4c0-1.1,0.5-2.2,1.2-3C7.1,8.8,7,8.3,7,7.6C7,7.2,7,6.6,7.3,6 c0,0,1.4,0,2.8,1.3C10.6,7.1,11.3,7,12,7s1.4,0.1,2,0.3C15.3,6,16.8,6,16.8,6C17,6.6,17,7.2,17,7.6c0,0.8-0.1,1.2-0.2,1.4 c0.7,0.8,1.2,1.8,1.2,3c0,2.2-1.7,3.5-4,4c0.6,0.5,1,1.4,1,2.3v2.6c0,0.3,0.3,0.6,0.7,0.5c3.7-1.5,6.3-5.1,6.3-9.3 C22,6.1,16.9,1.4,10.9,2.1z'></path>
          </svg>
        </a>
        {/* <a
          className='w-fit rounded outline-offset-4 flex items-center fill-gray-500 hover-guard:hover:fill-blue-600'
          href='https://reddit.com/r/prestigehunt'
          target='_blank'
          rel='noreferrer'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            aria-hidden='true'
          >
            <path d='M24 11.779c0-1.459-1.192-2.645-2.657-2.645-.715 0-1.363.286-1.84.746-1.81-1.191-4.259-1.949-6.971-2.046l1.483-4.669 4.016.941-.006.058c0 1.193.975 2.163 2.174 2.163 1.198 0 2.172-.97 2.172-2.163s-.975-2.164-2.172-2.164c-.92 0-1.704.574-2.021 1.379l-4.329-1.015c-.189-.046-.381.063-.44.249l-1.654 5.207c-2.838.034-5.409.798-7.3 2.025-.474-.438-1.103-.712-1.799-.712-1.465 0-2.656 1.187-2.656 2.646 0 .97.533 1.811 1.317 2.271-.052.282-.086.567-.086.857 0 3.911 4.808 7.093 10.719 7.093s10.72-3.182 10.72-7.093c0-.274-.029-.544-.075-.81.832-.447 1.405-1.312 1.405-2.318zm-17.224 1.816c0-.868.71-1.575 1.582-1.575.872 0 1.581.707 1.581 1.575s-.709 1.574-1.581 1.574-1.582-.706-1.582-1.574zm9.061 4.669c-.797.793-2.048 1.179-3.824 1.179l-.013-.003-.013.003c-1.777 0-3.028-.386-3.824-1.179-.145-.144-.145-.379 0-.523.145-.145.381-.145.526 0 .65.647 1.729.961 3.298.961l.013.003.013-.003c1.569 0 2.648-.315 3.298-.962.145-.145.381-.144.526 0 .145.145.145.379 0 .524zm-.189-3.095c-.872 0-1.581-.706-1.581-1.574 0-.868.709-1.575 1.581-1.575s1.581.707 1.581 1.575-.709 1.574-1.581 1.574z' />
          </svg>
        </a> */}
      </div>
    </footer>
  );
}

export default Footer;
