import { CheckCircleIcon } from '@heroicons/react/solid';

type ToggleButtonProps = {
  on: boolean;
  label: string;
  onChange: () => void;
};

function ToggleButton(props: ToggleButtonProps) {
  const handleClick = () => {
    props.onChange();
  };

  return (
    <button
      className={`group text-sm px-2 py-1.5 font-medium inline-flex flex-row items-center outline-offset-2 cursor-pointer rounded-lg ${
        props.on ? 'bg-blue-100' : 'bg-gray-100 hover-guard:hover:bg-gray-200'
      }`}
      aria-pressed={props.on}
      onClick={() => handleClick()}
    >
      {props.on ? (
        <CheckCircleIcon className='w-6 h-6 fill-blue-600' />
      ) : (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='currentColor'
          className='w-6 h-6 fill-gray-200 hover-guard:group-hover:fill-gray-300'
        >
          <path d='M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.3 2.25 12 2.25' />
        </svg>
      )}
      <span className='ml-2'>{props.label}</span>
    </button>
  );
}

export default ToggleButton;
