import { Snapshot } from '../models/snapshot';

export interface GetSnapshotsByCompanyIdOptions {
  after?: number;
}

export async function getSnapshotsByCompanyId(
  options?: GetSnapshotsByCompanyIdOptions
) {
  try {
    const params = new URLSearchParams();
    if (options?.after) {
      params.append('after', String(options.after));
    }

    const response = await fetch(
      `/.netlify/functions/api/snapshots?${params.toString()}`
    );
    if (response.status !== 200) {
      return new Map<string, Snapshot[]>();
    }

    return new Map<string, Snapshot[]>(Object.entries(await response.json()));
  } catch {
    return Promise.resolve(new Map<string, Snapshot[]>());
  }
}

export async function getSnapshots(companyId: string) {
  try {
    const response = await fetch(
      `/.netlify/functions/api/snapshots/${companyId}`
    );
    if (response.status !== 200) {
      return [];
    }

    return response.json() as Promise<Snapshot[]>;
  } catch {
    return Promise.resolve([]);
  }
}
