import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
    ArrowDownIcon, ArrowUpIcon, EmojiSadIcon, SearchIcon, XIcon
} from '@heroicons/react/outline';

import { ensureCategory } from '../models/category';
import { Company, isRanked, RankedCompany } from '../models/company';
import { CompaniesContext } from './App';
import CategoryTabs from './CategoryTabs';
import CompanyList from './CompanyList';
import DownloadCsvLink from './DownloadCsvLink';
import ErrorMessage from './ErrorMessage';
import InlineLink from './InlineLink';
import ToggleButton from './ToggleButton';

const DEFAULT_SHOWING_COUNT = 25;

function Rankings() {
  const [searchParams] = useSearchParams();
  const [rankedCompanies, setRankedCompanies] = useState<RankedCompany[]>([]);
  const [filterQuery, setFilterQuery] = useState('');
  const [showingCount, setShowingCount] = useState(DEFAULT_SHOWING_COUNT);
  const [showMoreButton, setShowMoreButton] = useState(true);
  const [hideAllButton, setHideAllButton] = useState(false);
  const [showScrollToButtons, setShowScrollToButtons] = useState(false);
  const [scrollToTopButtonDisabled, setScrollToTopButtonDisabled] =
    useState(true);
  const [scrollToBottomButtonDisabled, setScrollToBottomButtonDisabled] =
    useState(false);
  const [indexExpanded, setIndexExpanded] = useState(-1);
  const [noHFT, setNoHFT] = useState(false);
  const [onlyPreIPO, setOnlyPreIPO] = useState(false);
  const { companies, loading, error } = useContext(CompaniesContext);

  const selectedCategory = ensureCategory(searchParams.get('category'));
  const panelId = 'category-tab-panel';

  useEffect(() => {
    const rankedCompanies = companies
      .filter((company) => company.category === selectedCategory)
      .filter(isRanked)
      .filter((company) =>
        company.name.toLowerCase().startsWith(filterQuery.toLowerCase())
      )
      .filter((company) => {
        if (!noHFT) return true;
        return !company.isHFT;
      })
      .filter((company) => {
        if (!onlyPreIPO) return true;
        return company.isPreIPO;
      })
      .sort((a, b) => a.rank - b.rank);

    setShowMoreButton(rankedCompanies.length > showingCount);
    setHideAllButton(showingCount > DEFAULT_SHOWING_COUNT);
    setRankedCompanies(rankedCompanies.slice(0, showingCount));
  }, [
    selectedCategory,
    companies,
    filterQuery,
    showingCount,
    showMoreButton,
    hideAllButton,
    noHFT,
    onlyPreIPO,
  ]);

  useEffect(() => {
    document.title =
      'prestigehunt - Find the most prestigious companies to work for';
  }, []);

  const h1 = document.querySelector('h1');

  // TODO: Need to run this when show/hide buttons are clicked
  const handleScroll = useCallback(() => {
    if (h1) {
      setShowScrollToButtons(h1.offsetTop - window.scrollY <= 10);
      setScrollToTopButtonDisabled(h1.offsetTop - window.scrollY > 5);
    }
    setScrollToBottomButtonDisabled(
      Math.abs(
        document.body.scrollHeight - (window.scrollY + window.innerHeight)
      ) <= 5
    );
  }, [h1]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      const filterInput =
        document.querySelector<HTMLInputElement>('input[type="text"]');

      if (event.key === '/' && document.activeElement !== filterInput) {
        filterInput?.focus();
        event.preventDefault();
      }
    });
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterQuery(event.target.value);
    setIndexExpanded(-1);
  };

  const handleClearClick = () => {
    setIndexExpanded(-1);
    setFilterQuery('');
    focusInput();
  };

  const handleTabChange = () => {
    setFilterQuery('');
    setShowingCount(DEFAULT_SHOWING_COUNT);
    setIndexExpanded(-1);
    setNoHFT(false);
    setOnlyPreIPO(false);
  };

  const handleShowAllClick = () => {
    setShowingCount(Infinity);
  };

  const handleHideAllClick = () => {
    setShowingCount(DEFAULT_SHOWING_COUNT);
  };

  const handleScrollUpClick = () => {
    // 10px above the top of the H1 "Rankings"
    if (h1) {
      window.scrollTo({ top: h1.offsetTop - 10, behavior: 'smooth' });
    }
  };

  const handleScrollBottomClick = () => {
    // Bottom of the page
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  const handleItemClick = (index: number) => {
    setIndexExpanded(indexExpanded === index ? -1 : index);
  };

  const handleHFTChange = () => {
    setNoHFT(!noHFT);
    setIndexExpanded(-1);
  };

  const handlePreIPOChange = () => {
    setOnlyPreIPO(!onlyPreIPO);
    setIndexExpanded(-1);
  };

  const focusInput = () => {
    const filterInput =
      document.querySelector<HTMLInputElement>('input[type="text"]');
    filterInput?.focus();
  };

  const headers: (keyof Company)[] = [
    'id',
    'name',
    'rating',
    'rank',
    'tier',
    'headToHeads',
    'trending',
    'category',
    'website',
    'levelsWebsite',
    'blindWebsite',
    'isHFT',
    'isPreIPO',
  ];

  const getScrollToButtonClassName = (disabled: boolean) => {
    let className =
      'pointer-events-auto ml-auto rounded-full p-3 drop-shadow-xl outline-offset-2 text-white';
    className = `${className} ${
      disabled ? 'bg-gray-500/20' : 'bg-black/75 hover-guard:hover:bg-black/80'
    }`;

    return className;
  };

  return (
    <div>
      <p className='py-3 px-5 mb-5 bg-fuchsia-100 border-2 border-fuchsia-300 rounded-lg bottom-shadow'>
        <b className='font-semibold'>
          Looking for a holiday gift? Grab a{' '}
          <InlineLink
            to='https://prestigehunt.myshopify.com/products/prestigehunt-tee'
            text='prestigehunt tee'
            external={true}
          />
          !
        </b>{' '}
        Read more at our{' '}
        <InlineLink
          to='https://prestigehunt.bearblog.dev/thanks/'
          text=' special Thanksgiving update'
          external={true}
        />
        .
      </p>
      <p className='p-5 mb-5 bg-orange-100 border-2 border-orange-300 bottom-shadow rounded-lg'>
        <b className='font-semibold'>
          What are the most prestigious companies?{' '}
        </b>
        Is it considered more impressive to work at Meta or Google? Goldman or
        Morgan Stanley? BCG or Bain? These very important questions can now be
        answered. We rank companies by prestige for the industries where people
        care about this kind of stuff.
      </p>
      <h1 className='text-xl font-bold mb-1'>Rankings</h1>
      <p className='mb-1'>
        The current top prestigious companies by role. Rankings are updated
        daily and completely community-driven by head-to-head (H2H) prestige
        "matches".{' '}
        <InlineLink
          to='/about#rankings'
          text='Learn more'
          ariaLabel='Learn more about how our rankings work.'
        />
      </p>

      <p className='mb-5'>
        <DownloadCsvLink
          data={companies}
          headers={headers}
          text='Download the latest rankings data'
          fileName='latest_rankings_data.csv'
        />
      </p>

      <div
        className={`relative ${selectedCategory === 'tech' ? 'mb-3' : 'mb-5'}`}
      >
        <SearchIcon
          className='absolute top-[10px] left-[10px] w-5 h-5 stroke-gray-500 inline pointer-events-none'
          aria-hidden='true'
        />
        <input
          className='bg-gray-100 p-2 pl-10 pr-12 rounded-lg w-full placeholder:text-gray-500 hover-guard:hover:bg-gray-200'
          aria-controls={panelId}
          aria-label='Search companies by name'
          placeholder='Press "/" to search by name...'
          type='text'
          value={filterQuery}
          onChange={handleChange}
        />
        {filterQuery ? (
          <button
            className='rounded-md p-1 absolute top-[6px] right-[10px] group'
            aria-label='Clear search'
            onClick={handleClearClick}
          >
            <XIcon
              className='w-5 h-5 stroke-gray-500 hover-guard:group-hover:stroke-blue-600'
              aria-hidden='true'
            />
          </button>
        ) : null}
      </div>
      {selectedCategory === 'tech' ? (
        <div className='mb-4 flex flex-wrap gap-x-3 gap-y-1'>
          <div className='shrink-0'>
            <ToggleButton
              on={noHFT}
              label='No HFT or trading firms'
              onChange={handleHFTChange}
            />
          </div>
          <div className='shrink-0'>
            <ToggleButton
              on={onlyPreIPO}
              label='Pre-IPO'
              onChange={handlePreIPOChange}
            />
          </div>
        </div>
      ) : null}
      <CategoryTabs
        loadingPanelContent={loading}
        panelId={panelId}
        onTabChange={handleTabChange}
      >
        {error ? (
          <ErrorMessage />
        ) : (
          <div className='relative'>
            {rankedCompanies.length > 0 ? (
              <CompanyList
                companies={rankedCompanies}
                indexExpanded={indexExpanded}
                onItemClick={handleItemClick}
              />
            ) : (
              <div className='text-gray-600 flex items-center justify-center'>
                {filterQuery ? (
                  <span>
                    <EmojiSadIcon className='w-5 h-5 mr-1 hidden xsm:inline' />
                    No companies found for this search.{' '}
                    <InlineLink to='/add' text='Add one?' />
                  </span>
                ) : (
                  <span>
                    <EmojiSadIcon className='w-5 h-5 mr-1 hidden xsm:inline' />
                    No companies found.
                  </span>
                )}
              </div>
            )}
            {showMoreButton ? (
              <div className='text-center mt-5'>
                <button
                  className=' bg-gray-100 hover-guard:hover:bg-gray-200 sm:max-w-xs w-full rounded-lg py-2 px-3 font-medium outline-offset-2'
                  onClick={handleShowAllClick}
                >
                  Show all
                </button>
              </div>
            ) : undefined}
            {hideAllButton ? (
              <div className='text-center mt-5'>
                <button
                  className=' bg-gray-100 hover-guard:hover:bg-gray-200 sm:max-w-xs w-full rounded-lg py-2 px-3 font-medium outline-offset-2'
                  onClick={handleHideAllClick}
                >
                  Hide all
                </button>
              </div>
            ) : undefined}
            {showScrollToButtons ? (
              <div>
                <div className='fixed left-0 right-4 bottom-24 pointer-events-none z-10'>
                  <div className='max-w-screen-md min-w-[320px] mx-auto px-5 w-full flex'>
                    <button
                      aria-label='Scroll to top'
                      className={getScrollToButtonClassName(
                        scrollToTopButtonDisabled
                      )}
                      onClick={handleScrollUpClick}
                      disabled={scrollToTopButtonDisabled}
                    >
                      <ArrowUpIcon className='w-5 h-5' />
                    </button>
                  </div>
                </div>
                <div className='fixed left-0 right-4 bottom-10 pointer-events-none z-10'>
                  <div className='max-w-screen-md min-w-[320px] mx-auto px-5 w-full flex'>
                    <button
                      aria-label='Scroll to bottom'
                      className={getScrollToButtonClassName(
                        scrollToBottomButtonDisabled
                      )}
                      onClick={handleScrollBottomClick}
                      disabled={scrollToBottomButtonDisabled}
                    >
                      <ArrowDownIcon className='w-5 h-5' />
                    </button>
                  </div>
                </div>
              </div>
            ) : undefined}
          </div>
        )}
      </CategoryTabs>
    </div>
  );
}

export default Rankings;
