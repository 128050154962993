import Logo from './Logo';

function Loading() {
  return (
    <div className='flex flex-col items-center animate-bounce'>
      <Logo aria-hidden='true' className='w-10 h-10 fill-gray-400' />
    </div>
  );
}

export default Loading;
