import { Outlet } from 'react-router-dom';

function Main() {
  return (
    <main className='max-w-screen-md min-w-[320px] mx-auto px-5 mt-5'>
      <Outlet />
    </main>
  );
}

export default Main;
