import { Link } from 'react-router-dom';

type InlineLinkProps = {
  to: string;
  text: string;
  ariaLabel?: string;
  external?: boolean;
};

function InlineLink(props: InlineLinkProps) {
  if (props.external) {
    return (
      <a
        className='underline underline-offset-2 decoration-2 rounded outline-offset-4 text-blue-600 hover-guard:hover:text-blue-800'
        href={props.to}
        target='_blank'
        rel='noreferrer'
        aria-label={props.ariaLabel}
      >
        {props.text}
      </a>
    );
  }

  return (
    <Link
      className='underline underline-offset-2 decoration-2 rounded outline-offset-4 text-blue-600 hover-guard:hover:text-blue-800'
      to={props.to}
      aria-label={props.ariaLabel}
    >
      {props.text}
    </Link>
  );
}

export default InlineLink;
