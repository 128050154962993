import InlineLink from './InlineLink';

function Recaptcha() {
  return (
    <div className='text-sm text-gray-500 mt-5'>
      This site is protected by reCAPTCHA and the Google{' '}
      <InlineLink
        to='https://policies.google.com/privacy'
        text='Privacy Policy'
      />{' '}
      and{' '}
      <InlineLink
        to='https://policies.google.com/terms'
        text='Terms of Service'
      />{' '}
      apply.
    </div>
  );
}

export default Recaptcha;
