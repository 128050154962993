import { DownloadIcon } from '@heroicons/react/outline';

type DownloadCsvLinkProps<T> = {
  data: T[];
  headers: (keyof T)[];
  fileName: string;
  text: string;
};

function DownloadCsvLink<T>(props: DownloadCsvLinkProps<T>) {
  const rows = props.data.map((x) => {
    return props.headers.map((header) => x[header]);
  });
  const blobPart = arraytoCsvString([props.headers, ...rows]);
  const blob = new Blob([blobPart], {
    type: 'text/csv;charset=utf-8;',
  });
  const url = URL.createObjectURL(blob);

  return (
    <a
      href={url}
      download={props.fileName}
      className='text-gray-500 text-sm rounded outline-offset-4 hover-guard:hover:text-blue-600'
    >
      <DownloadIcon className='w-4 h-4 inline align-text-bottom' /> {props.text}
    </a>
  );
}

// Converts a 2d array into a csv string
function arraytoCsvString(data: unknown[][]) {
  return data
    .map((row) =>
      row
        .map((v) => (v === undefined ? '' : String(v)))
        .map((v) => v.replaceAll('"', '""'))
        .map((v) => `"${v}"`)
        .join(',')
    )
    .join('\r\n');
}

export default DownloadCsvLink;
