export type Tier = 'S' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F';

export const TIERED_SUBSET = 35;

export const TIERS: ['S', 'A', 'B', 'C', 'D', 'E', 'F'] = [
  'S',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
];
