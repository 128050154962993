import { useEffect } from 'react';

import { TIERS } from '../models/tier';
import InlineLink from './InlineLink';
import TierBadge from './TierBadge';

function About() {
  useEffect(() => {
    const hash = window.location.hash.slice(1);
    if (hash) {
      const fragment = document.getElementById(hash);
      if (fragment) {
        fragment.scrollIntoView();
      }
    } else {
      // Unclear why this is needed.
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    document.title = 'prestigehunt - About';
  }, []);

  const getH2$ = (text: string) => {
    const id = text.toLowerCase().replaceAll('?', '').split(' ').join('-');
    return (
      <h2 className='text-xl font-bold mb-3 mt-7 pt-3' id={id}>
        {text}{' '}
        <a
          aria-label={text + ' section heading'}
          className='text-gray-400 ml-1 hover-guard:hover:text-blue-600 outline-offset-2 rounded'
          href={'#' + id}
        >
          #
        </a>
      </h2>
    );
  };

  const getTiers$ = () => {
    return TIERS.map((tier, i) => {
      return (
        <span key={tier}>
          <TierBadge className='mr-1.5 my-px' tier={tier} />
          {i === TIERS.length - 2 ? 'and ' : ''}
        </span>
      );
    });
  };

  return (
    <div>
      <h1 className='text-xl font-bold mb-3' id='about'>
        About
      </h1>
      <p>
        In certain industries, people care a lot about prestige and status. When
        deciding where to work, sometimes it's even the primary motivator. To
        make these evaluations easier, we provide a
        <b className='font-semibold'> canonical prestige ranking </b>
        for the industries that it tends to matter the most in.
      </p>
      {getH2$('On prestige')}
      <p>
        <b className='font-bold'>prestige</b>
        <span className='font-semibold mx-1 italic'>n.</span>
        the respect and admiration that someone or something gets for being
        successful and important.
      </p>
      <div className='font-bold text-gray-400 text-center my-3'>&#8212;</div>
      <p>
        Prestige is tricky to measure because it varies based upon one's
        perspective and experience. Laypeople, industry experts, and recruiters
        all have different ideas of what the most prestigious companies are. On
        prestigehunt, we avoid prescribing any particular perspective over
        another for this reason.
      </p>
      {getH2$('Rankings')}
      <p className='mb-5'>
        Rankings are determined by head-to-head (H2H) prestige "matches" between
        companies. In head-to-head matches, we display two random companies and
        ask you to choose which is more prestigious. You should make this
        decision based upon your own perspective.
      </p>
      <p className='mb-5'>
        At the end of each day, we aggregate the results of all incoming
        head-to-head matches and update company rankings accordingly. We
        currently use the{' '}
        <InlineLink
          to='http://en.wikipedia.org/wiki/Elo_rating_system'
          text='Elo rating system'
          external={true}
        />{' '}
        with a dynamic{' '}
        <InlineLink
          to='http://en.wikipedia.org/wiki/Elo_rating_system#Most_accurate_K-factor'
          text='K-factor'
          external={true}
        />{' '}
        based upon number of matches played. All companies start with the same
        default rating.
      </p>
      <p>
        <b className='font-semibold'>
          Ultimately, our rankings are determined by user data.
        </b>{' '}
        This means that our rankings exactly represent the opinions and
        incentives of our users. If our rankings seem questionable, then that's
        because our users are questionable.
      </p>
      {getH2$('Companies')}
      <p className='mb-5'>
        We began with a small, base set of companies for each role and regularly
        review proposed companies from our users via our{' '}
        <InlineLink to='/add' text='Add' /> page. We review submissions to
        ensure they are relevant for the role in question and to discard the
        massive amount of spam we receive. Once in the rankings, we only remove
        companies if they are no longer applicable to the given role (e.g.
        they've gone bankrupt or no longer hire for that particular role).
      </p>
      <p>
        When a company is added, it doesn't appear in the rankings immediately.
        There is a calibration period where the company will participate in
        head-to-head matches but not appear in the rankings. This is done
        because a company's ranking tends to be very volatile when initially
        added.
      </p>
      {getH2$('Tiers')}
      <p>
        The top 33 companies are split into {TIERS.length} tiers: {getTiers$()}.
        Tiers have hardcoded sizes. The top tier is comprised of the top 3
        companies, while each subsequent tier is comprised of the next 5
        companies (for a total of 33 tiered companies).
      </p>
      {getH2$('Roles')}
      <p>
        Prestige rankings are categorized by{' '}
        <b className='font-semibold'>role as opposed to industry</b>. Roles are
        defined as follows:
      </p>
      <ul className='list-disc mt-5 ml-5 space-y-2'>
        <li>
          <b className='font-semibold'>Tech: </b> Includes any tech-related
          roles such as Software Engineer, Data Scientist, Project Manager, UX
          Designer, etc. Not limited to tech industries (e.g. a tech role at
          Goldman).
        </li>
        <li>
          <b className='font-semibold'>Finance: </b> Includes any finance roles,
          but the most prestigious tend to be roles in IB, PE, hedge funds, etc.
          Not limited to finance industries (e.g. a finance role at Google).
        </li>
        <li>
          <b className='font-semibold'>Consulting: </b> Includes all management
          or strategy consulting roles (e.g. not design consulting roles at
          companies like Ideo or Frog). Again, not limited to consulting
          companies, any company that has a consulting arm (e.g. a consulting
          role at Adobe).
        </li>
      </ul>
      <p className='mt-5'>
        If these roles need to be changed or made more granular in order to be
        useful, please let me know.
      </p>
      {getH2$('Is this a joke?')}
      <p>Not at all. This is a very, very serious project.</p>
      {getH2$("What's your stack?")}
      <ul className='list-disc mt-5 ml-5 space-y-2'>
        <li>
          <InlineLink
            to='https://create-react-app.dev'
            text='Create React App'
            external={true}
          />{' '}
          to bootstrap the project.
        </li>
        <li>
          React, TypeScript, and{' '}
          <InlineLink
            to='https://tailwindcss.com'
            text='Tailwind CSS'
            external={true}
          />{' '}
          on the client.
        </li>
        <li>
          <InlineLink
            to='https://firebase.google.com'
            text='Firebase'
            external={true}
          />{' '}
          for a tiny database.
        </li>
        <li>
          <InlineLink to='https://netlify.com' text='Netlify' external={true} />{' '}
          for hosting and lightweight backend Node.js functions.
        </li>
      </ul>
      {getH2$('Can I advertise a job opportunity on your site?')}
      <p>
        Yes! We have one highly-coveted ad spot on our rankings list for this
        purpose. Please contact us for more details.
      </p>
      {getH2$('I found a bug / have an idea for a feature!')}
      <p>
        Great!{' '}
        <InlineLink
          to='https://github.com/prestigehunt/prestigehunt'
          text='Our GitHub repo'
          external={true}
        />{' '}
        is the best place to open an issue or start a discussion.
      </p>
      {getH2$('Contact')}
      <p>
        Reach out to us at{' '}
        <InlineLink
          to='mailto:hey@prestigehunt.com'
          text='hey@prestigehunt.com'
          external={true}
        />
        .
      </p>
    </div>
  );
}

export default About;
